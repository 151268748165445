//
// FONTS
//

// cyrillic
@font-face
  font-family: 'PT Sans'
  font-style: italic
  font-weight: 400
  font-display: auto
  src: local('PT Sans Italic'), local('PTSans-Italic'), url(https://fonts.gstatic.com/s/ptsans/v9/jizYRExUiTo99u79D0e0w8mIAjcQ-woy.woff2) format('woff2')
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116

// latin
@font-face
  font-family: 'PT Sans'
  font-style: italic
  font-weight: 400
  font-display: auto
  src: local('PT Sans Italic'), local('PTSans-Italic'), url(https://fonts.gstatic.com/s/ptsans/v9/jizYRExUiTo99u79D0e0x8mIAjcQ-w.woff2) format('woff2')
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD

// cyrillic
@font-face
  font-family: 'PT Sans'
  font-style: normal
  font-weight: 400
  font-display: auto
  src: local('PT Sans'), local('PTSans-Regular'), url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0aExcOPIDUg-g.woff2) format('woff2')
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116

// latin
@font-face
  font-family: 'PT Sans'
  font-style: normal
  font-weight: 400
  font-display: auto
  src: local('PT Sans'), local('PTSans-Regular'), url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0KExcOPIDU.woff2) format('woff2')
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
// cyrillic
@font-face
  font-family: 'PT Sans'
  font-style: normal
  font-weight: 700
  font-display: auto
  src: local('PT Sans Bold'), local('PTSans-Bold'), url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OqtLR8a8zILig.woff2) format('woff2')
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116

// latin
@font-face
  font-family: 'PT Sans'
  font-style: normal
  font-weight: 700
  font-display: auto
  src: local('PT Sans Bold'), local('PTSans-Bold'), url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0O6tLR8a8zI.woff2) format('woff2')
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
